import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useUser } from "./UserContext";
import './styles.css'; // Ensure this file contains your provided CSS

const RegisterPage = () => {
  const { login } = useUser();
  const navigate = useNavigate();

  // Registration state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [interest, setInterest] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Toggle state for switching between Sign Up and Sign In views
  // In this example, when the container has the "active" class, the sign‑up form is visible.
  const [isActive, setIsActive] = useState(true);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const onboardingResponse = await axios.post(
        "https://api.infradelta.com/onboarding/aws",
        {
          firstName,
          lastName,
          email,
          password,
          interest,
        }
      );

      console.log("Onboarding Response:", onboardingResponse.data);
      alert("User successfully created");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error occurred while calling the APIs:", error);
      alert("Failed to register user in one or both services");
    }
  };

  const toggleView = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`container ${isActive ? "active" : ""}`} id="container">
      {/* SIGN UP FORM */}
      <div className="form-container sign-up">
        <form onSubmit={handleRegister}>
          <h1>Create Account</h1>
          
          <span>or use your email for registration</span>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Area of Interest"
            value={interest}
            onChange={(e) => setInterest(e.target.value)}
          />
          <button type="submit">Sign Up</button>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
        </form>
      </div>

      {/* SIGN IN FORM (UI Only; you can wire this up or navigate to your login route) */}
      <div className="form-container sign-in">
        <form onSubmit={(e) => { e.preventDefault(); navigate("/login"); }}>
          <h1>Sign In</h1>
          <div className="social-icons">
            <a href="#" className="icon">
              <i className="fa-brands fa-google-plus-g"></i>
            </a>
            <a href="#" className="icon">
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a href="#" className="icon">
              <i className="fa-brands fa-github"></i>
            </a>
            <a href="#" className="icon">
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
          </div>
          <span>or use your email password</span>
          <input type="email" placeholder="Email" required />
          <input type="password" placeholder="Password" required />
          <a href="#">Forget Your Password?</a>
          <button type="submit">Sign In</button>
        </form>
      </div>

      {/* TOGGLE PANEL */}
      <div className="toggle-container">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1>Welcome Back!</h1>
            <p>Enter your personal details to use all of site features</p>
            <button type="button" onClick={() => navigate("/login")}>Sign Up</button>


            
          </div>
          

          <div className="toggle-panel toggle-right">
            <h1>Hello, Friend!</h1>
            <p>Register with your personal details to use all of site features</p>
            <button className="hidden" id="register" onClick={toggleView}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
