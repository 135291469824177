import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useUser } from "./UserContext";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import {jwtDecode} from "jwt-decode";
import "./styles.css"; // Ensure your CSS and Font Awesome are imported

const LoginPage = () => {
  const { login } = useUser();
  const navigate = useNavigate();

  // ------------------
  // Login State & Functions
  // ------------------
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();

    const payload = {
      body: JSON.stringify({
        email: loginEmail,
        password: loginPassword,
      }),
    };

    try {
      const response = await fetch("https://api.infradelta.com/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const rawResponse = await response.text();
      console.log("Raw Response:", rawResponse);

      let data;
      try {
        data = JSON.parse(rawResponse);
      } catch (err) {
        console.error("Error parsing response body:", err);
        setLoginError("An error occurred while processing the response.");
        return;
      }

      console.log("Parsed Data:", data);

      if (data.statusCode === 200) {
        const user = JSON.parse(data.body);
        console.log("Parsed User Data:", user);

        if (user && user.email && user.firstName && user.lastName) {
          setLoginError("");
          login(user);
          navigate("/dashboard");
        } else {
          setLoginError("An error occurred.");
        }
      } else if (data.statusCode === 404) {
        console.log("User not found, statusCode 404");
        if (data.body && JSON.parse(data.body).error === "User not found") {
          setLoginError("User not found. Switching to registration.");
          setIsActive(true);
        } else {
          setLoginError(data.error || "An error occurred. Please try again.");
        }
      } else if (data.statusCode === 401) {
        console.log("Incorrect Username or Password");
        setLoginError("Incorrect Username or Password.");
      } else if (data.statusCode === 500) {
        console.error("Internal Server Error:", data.body);
        setLoginError("Server error. Please try again later.");
      } else {
        setLoginError(data.error || "An error occurred. Please try again.");
      }
    } catch (err) {
      console.error("Login error:", err);
      setLoginError("An error occurred. Please try again.");
    }
  };



  const handleGoogleSuccess = async (response) => {
    const token = response.credential;
    try {
      const decoded = jwtDecode(token);
      console.log("test1 Decoded JWT:", decoded);
      
      const { email, given_name: firstName, family_name: lastName } = decoded;

      const response1 =  await axios.post(
        "https://api.infradelta.com/onboarding/aws",
        {
          firstName,
          lastName,
          email: regEmail
        }
      );
      console.log("test2 :", response1.status);

      if (response1.status !== 200) {
        setError(response1.data?.error || 'Could not save user details');
        return;
      }

      console.log('User saved successfully');
      setSuccess('Registration successful! Redirecting to dashboard...');
      login({ firstName, lastName, regEmail });



      navigate("/dashboard");
      
      //const { email, given_name: firstName, family_name: lastName } = decoded;

      login({ firstName, lastName, email });

      setLoginError("");

    } catch (error) {
      setLoginError("Google login failed. Please try again.");
      console.error("Google login error:", error);
    }
  };

  const handleGoogleFailure = (error) => {
    setLoginError("Google login failed. Please try again.");
    console.error("Google login error:", error);
  };

  // ------------------
  // Registration State & Functions
  // ------------------
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [regEmail, setRegEmail] = useState("");
  const [regPassword, setRegPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [interest, setInterest] = useState("");
  const [regError, setRegError] = useState("");
  const [regSuccess, setRegSuccess] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    setRegError("");
    setRegSuccess("");

    if (regPassword !== confirmPassword) {
      setRegError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.infradelta.com/onboarding/aws",
        {
          firstName,
          lastName,
          email: regEmail,
          password: regPassword,
          interest:interest
        }
      );

      if (response.status !== 200) {
        setError(response.data?.error || 'Could not save user details');
        return;
      }

      console.log('User saved successfully');
      setSuccess('Registration successful! Redirecting to dashboard...');
      login({ firstName, lastName, regEmail });
      navigate("/dashboard");
    } catch (err) {
      console.error('Error saving user:', err.response?.data || err.message);
      setError(`Failed to save user: ${err.response?.data?.error || err.message}`);
    }
  };

  // ------------------
  // Toggle Between Login and Register Views
  // ------------------
  const [isActive, setIsActive] = useState(false);
  const toggleView = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`container ${isActive ? "active" : ""}`} id="container">
      {/* Registration Form */}
      <div className="form-container sign-up">
        <form onSubmit={handleRegister}>
          <h1>Create Account</h1>
          <div className="social-icons">
            <GoogleOAuthProvider clientId="814822929060-t4bugt7kamnt04uuft7ht8ug78kusilb.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleFailure}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="icon"
                    type="button"
                  >
                    <i className="fa-brands fa-google-plus-g"></i>
                  </button>
                )}
              />
            </GoogleOAuthProvider>
            
          </div>
          <span>or use your email for registration</span>
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={regEmail}
            onChange={(e) => setRegEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={regPassword}
            onChange={(e) => setRegPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Area of Interest"
            value={interest}
            onChange={(e) => setInterest(e.target.value)}
          />
          <button type="submit">Sign Up</button>
          {regError && (
            <p style={{ color: "#EF4444", fontSize: "14px", marginTop: "16px" }}>
              {regError}
            </p>
          )}
          {regSuccess && (
            <p style={{ color: "#10B981", fontSize: "14px", marginTop: "16px" }}>
              {regSuccess}
            </p>
          )}
        </form>
      </div>

      {/* Login Form */}
      <div className="form-container sign-in">
        <form onSubmit={handleLogin}>
          <h1>Sign In</h1>
          <div className="social-icons">
            <GoogleOAuthProvider clientId="814822929060-t4bugt7kamnt04uuft7ht8ug78kusilb.apps.googleusercontent.com">
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleFailure}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="icon"
                    type="button"
                  >
                    <i className="fa-brands fa-google-plus-g"></i>
                  </button>
                )}
              />
            </GoogleOAuthProvider>
            
          </div>
          <span>or use your email password</span>
          <input
            type="email"
            placeholder="Email"
            value={loginEmail}
            onChange={(e) => setLoginEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={loginPassword}
            onChange={(e) => setLoginPassword(e.target.value)}
            required
          />
          <a href="#">Forget Your Password?</a>
          <button type="submit">Sign In</button>
          {loginError && (
            <p style={{ color: "#EF4444", fontSize: "14px", marginTop: "16px" }}>
              {loginError}
            </p>
          )}
        </form>
      </div>

      {/* Toggle Panel */}
      <div className="toggle-container">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1>Welcome Back!</h1>
            <p>Enter your personal details to use all of site features</p>
            <button className="hidden" id="login" onClick={toggleView}>
              Sign In
            </button>
          </div>
          <div className="toggle-panel toggle-right">
            <h1>Hello, Friend!</h1>
            <p>Register with your personal details to use all of site features</p>
            <button type="button" onClick={() => navigate("/register")}>Sign Up</button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
