import React, { useState, useEffect, useRef } from "react";
import { useUser } from "./UserContext"; // Adjust path as needed
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula, prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  FaSun,
  FaMoon,
  FaEdit,
  FaTrash,
  FaCopy,
  FaThumbsUp,
  FaThumbsDown,
  FaPaperclip,
  FaPaperPlane,
  FaTimes,
  FaBrain,
  FaSignOutAlt,
  FaBars,
  FaCaretDown,
  FaCheck,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Chatbot = () => {
  const { user, logout } = useUser();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(true); // default dark mode
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [suggestedQuestions] = useState([
    "What is the weather today?",
    "Tell me a joke.",
    "How do I make pasta?",
  ]);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editInput, setEditInput] = useState("");
  const [showModelSelection, setShowModelSelection] = useState(false);
  const [availableModels] = useState([
    { id: "model1", name: "Model One" },
    { id: "model2", name: "Model Two" },
  ]);
  const [conversations] = useState([]); // Dummy conversation list
  const [isBotTyping, setIsBotTyping] = useState(false);

  // Extended model list to mimic your screenshot
  const modelOptions = [
    {
      id: "linerBasic",
      name: "Liner Basic",
      description: "Liner’s most compact model for simple tasks",
      best: false,
    },
    {
      id: "linerPro",
      name: "Liner Pro",
      description: "Liner’s most powerful model for highly complex tasks",
      best: true,
    },
    {
      id: "openAIMini",
      name: "OpenAI o3-mini",
      description: "",
      best: false,
    },
    {
      id: "gpt4o",
      name: "GPT-4o",
      description: "",
      best: false,
    },
  ];

  // State for the model dropdown
  const [selectedModel, setSelectedModel] = useState(null);
  const [showModelDropdown, setShowModelDropdown] = useState(false);

  const messagesEndRef = useRef(null);
  const sidebarRef = useRef(null);
  const userName = user?.firstName || "User";

  // NEW: ref for the model dropdown container
  const modelDropdownRef = useRef(null);

  // Fetch message history on mount (if user exists)
  useEffect(() => {
    if (user && user.email) {
      fetchMessageHistory(user.email);
    }
  }, [user]);

  // Scroll to bottom whenever messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Close sidebar when clicking outside of it
  useEffect(() => {
    const handleClickOutsideSidebar = (event) => {
      if (
        isSidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setIsSidebarOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideSidebar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
    };
  }, [isSidebarOpen]);

  // NEW: Close model dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (
        showModelDropdown &&
        modelDropdownRef.current &&
        !modelDropdownRef.current.contains(event.target)
      ) {
        setShowModelDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDropdown);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDropdown);
    };
  }, [showModelDropdown]);

  // Fetch message history from your API
  const fetchMessageHistory = async (email) => {
    try {
      const response = await fetch("https://api.infradelta.com/chat/getmessages", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ body: JSON.stringify({ email }) }),
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch message history for ${email}`);
      }
      const data = await response.json();
      console.log("Fetched chat message history:", data);
      if (data && data.body) {
        try {
          const parsedBody = JSON.parse(data.body);
          if (Array.isArray(parsedBody.messages)) {
            const flatMessages = parsedBody.messages.flat(Infinity);
            const msgs = flatMessages.map((msg, index) => ({
              id: msg.id || index,
              ...msg,
            }));
            setMessages(msgs);
          } else {
            console.error("Messages are not in the expected array format:", parsedBody);
          }
        } catch (parseError) {
          console.error("Error parsing response body:", parseError);
        }
      } else {
        console.error("Invalid response structure:", data);
      }
    } catch (error) {
      console.error("Error fetching message history:", error);
    }
  };

  // Send message (with optional parameter for suggested questions)
  const handleSend = async (messageToSendParam) => {
    const messageToSend = messageToSendParam !== undefined ? messageToSendParam : input;
    if (!messageToSend.trim()) return;
    if (!user || !user.email) {
      console.error("User is not defined or missing email.");
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now(),
          role: "assistant",
          content: "User information is missing. Please log in again.",
          sender: "AI Operator",
          type: "text",
        },
      ]);
      return;
    }

    const userMessage = {
      id: Date.now(),
      role: "user",
      content: messageToSend,
      sender: userName,
      type: "text",
    };
    setMessages((prev) => [...prev, userMessage]);
    if (messageToSendParam === undefined) {
      setInput("");
    }

    try {
      const response = await fetch("https://api.infradelta.com/chat/chat", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          body: JSON.stringify({ email: user.email, message: messageToSend }),
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Response from Lambda:", data.body);
      let replyMessage;
      try {
        const parsedBody = data.body ? JSON.parse(data.body) : null;
        replyMessage = parsedBody?.reply || "No response provided by AI.";
      } catch (parseError) {
        console.error("Failed to parse response body:", parseError);
        replyMessage = "The server response could not be processed.";
      }

      const imageRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/i;
      const messageType = imageRegex.test(replyMessage) ? "image" : "text";

      const aiMessage = {
        id: Date.now() + 1,
        role: "assistant",
        content: replyMessage,
        sender: "GenAI",
        type: messageType,
      };
      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.error("Error in handleSend:", error);
      setMessages((prev) => [
        ...prev,
        {
          id: Date.now(),
          role: "assistant",
          content: "Something went wrong. Please try again later.",
          sender: "GenAI",
          type: "text",
        },
      ]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  const handleLogout = () => {
    if (logout) {
      logout();
    } else {
      console.log("Logout functionality not implemented");
    }
    navigate("/login");
  };

  const handleFileChange = (e) => {
    console.log("File selected:", e.target.files[0]);
  };

  // Message actions
  const handleEditMessage = (id, content) => {
    setEditingMessageId(id);
    setEditInput(content);
  };
  const handleSaveEdit = () => {
    setMessages((prev) =>
      prev.map((msg) => (msg.id === editingMessageId ? { ...msg, content: editInput } : msg))
    );
    setEditingMessageId(null);
    setEditInput("");
  };
  const handleDeleteMessage = (id) =>
    setMessages((prev) => prev.filter((msg) => msg.id !== id));
  const handleCopyMessage = (content) => {
    navigator.clipboard.writeText(content);
    alert("Message copied to clipboard!");
  };
  const handleFeedback = (id, feedback) => {
    setMessages((prev) =>
      prev.map((msg) => (msg.id === id ? { ...msg, feedback } : msg))
    );
  };

  // Dummy functions for model selection and suggested question
  const proceedWithSelectedModel = () => setShowModelSelection(false);
  const handleStopGenerating = () => setIsBotTyping(false);
  const handleSuggestedQuestionClick = (question) => {
    handleSend(question);
  };

  const flattenedMessages = messages.flat(Infinity);

  // Select model from dropdown
  const handleSelectModel = (option) => {
    setSelectedModel(option);
    setShowModelDropdown(false);
  };

  return (
    <>
      <style>
        {`
          html, body {
            margin: 0;
            padding: 0;
            overflow-x: hidden; /* Prevent horizontal scroll */
            background: #18181B; /* Match background color */
          }
        `}
      </style>

      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          backgroundColor: isDarkMode ? "#18181B" : "#FFFFFF",
          color: isDarkMode ? "#FFFFFF" : "#000000",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* HEADER */}
        <div
          style={{
            width: "100%",
            padding: "16px 24px",
            borderBottom: "1px solid",
            borderColor: isDarkMode ? "#27272A" : "#E5E7EB",
            display: "flex",
            justifyContent: "center", // center the dropdown
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* Centered Model Dropdown */}
          <div style={{ position: "relative" }} ref={modelDropdownRef}>
            <button
              onClick={() => setShowModelDropdown(!showModelDropdown)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                backgroundColor: "transparent",
                border: "none",
                color: isDarkMode ? "#FFFFFF" : "#000000",
                fontSize: "15px",
                cursor: "pointer",
              }}
            >
              {selectedModel ? selectedModel.name : "Select the model"}
              <FaCaretDown size={14} />
            </button>

            {showModelDropdown && (
              <div
                style={{
                  position: "absolute",
                  top: "105%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  minWidth: "220px",
                  backgroundColor: isDarkMode ? "#27272A" : "#FFFFFF",
                  border: "1px solid",
                  borderColor: isDarkMode ? "#3F3F46" : "#E5E7EB",
                  borderRadius: "8px",
                  padding: "8px 0",
                  zIndex: 1000,
                  boxShadow: isDarkMode
                    ? "0 2px 8px rgba(0,0,0,0.7)"
                    : "0 2px 8px rgba(0,0,0,0.2)",
                }}
              >
                {/* Title */}
                <div
                  style={{
                    padding: "8px 16px 4px",
                    fontWeight: "bold",
                    color: isDarkMode ? "#FFFFFF" : "#000000",
                    fontSize: "14px",
                  }}
                >
                  Model
                </div>
                {/* Model Options */}
                {modelOptions.map((option) => (
                  <button
                    key={option.id}
                    onClick={() => handleSelectModel(option)}
                    style={{
                      display: "block",
                      width: "100%",
                      textAlign: "left",
                      padding: "8px 16px",
                      border: "none",
                      backgroundColor: "transparent",
                      color: isDarkMode ? "#E4E4E7" : "#1F2937",
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 600,
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                          }}
                        >
                          {option.name}
                          {option.best && (
                            <span
                              style={{
                                backgroundColor: "#6366F1",
                                color: "#FFFFFF",
                                fontSize: "10px",
                                padding: "2px 6px",
                                borderRadius: "4px",
                              }}
                            >
                              Best
                            </span>
                          )}
                        </div>
                        {selectedModel?.id === option.id && (
                          <FaCheck size={14} style={{ marginLeft: "8px" }} />
                        )}
                      </div>
                      {option.description && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: isDarkMode ? "#A1A1AA" : "#6B7280",
                          }}
                        >
                          {option.description}
                        </div>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Dark/Light Mode Toggle (on the right) */}
          <button
            onClick={toggleDarkMode}
            style={{
              position: "absolute",
              right: "24px",
              padding: "8px",
              backgroundColor: "transparent",
              color: isDarkMode ? "#FFFFFF" : "#000000",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isDarkMode ? <FaSun size={20} /> : <FaMoon size={20} />}
          </button>
        </div>

        {/* MAIN CHAT CONTAINER */}
        <div
          style={{
            width: "100%",
            maxWidth: "800px",
            height: "calc(100vh - 140px)",
            padding: "0 24px",
            overflow: "auto",
            flex: 1,
            margin: "0 auto",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
          className="hide-scrollbar"
        >
          {/* Welcome Message */}
          {flattenedMessages.length === 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                gap: "24px",
              }}
            >
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: isDarkMode ? "#FFFFFF" : "#000000",
                }}
              >
                Welcome to GenX - Artificial Intelligence...!
              </div>
              <p
                style={{
                  color: isDarkMode ? "#A1A1AA" : "#4B5563",
                  textAlign: "center",
                  maxWidth: "600px",
                }}
              >
                I'm an AI assistant that combines web search with AI to provide up-to-date information.
              </p>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: "12px",
                  width: "100%",
                  maxWidth: "600px",
                }}
              >
                <p
                  style={{
                    color: isDarkMode ? "#A1A1AA" : "#4B5563",
                    textAlign: "center",
                    marginBottom: "4px",
                  }}
                >
                  Try asking me something:
                </p>
                {suggestedQuestions.map((question, index) => (
                  <button
                    key={index}
                    onClick={() => handleSuggestedQuestionClick(question)}
                    style={{
                      padding: "12px 16px",
                      backgroundColor: isDarkMode ? "#27272A" : "#F3F4F6",
                      color: isDarkMode ? "#FFFFFF" : "#000000",
                      border: "1px solid",
                      borderColor: isDarkMode ? "#3F3F46" : "#E5E7EB",
                      borderRadius: "8px",
                      cursor: "pointer",
                      fontSize: "14px",
                      textAlign: "left",
                    }}
                  >
                    {question}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Chat Messages */}
          {flattenedMessages.map((msg, index) => {
            const isUserMessage = msg.role === "user";
            const displayName = isUserMessage
              ? userName
              : msg.sender === "assistant"
              ? "GenAI"
              : msg.sender || "GenAI";
            return (
              <div
                key={msg.id || index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px 0",
                  borderBottom:
                    index < flattenedMessages.length - 1 ? "1px solid" : "none",
                  borderColor: isDarkMode ? "#27272A" : "#E5E7EB",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "12px",
                    marginBottom: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      backgroundColor: isUserMessage ? "#3B82F6" : "#10B981",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#FFFFFF",
                      fontWeight: "bold",
                    }}
                  >
                    {displayName.charAt(0).toUpperCase()}
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: isDarkMode ? "#FFFFFF" : "#000000",
                    }}
                  >
                    {displayName}
                  </div>
                </div>
                {editingMessageId === msg.id ? (
                  <div style={{ marginLeft: "44px" }}>
                    <input
                      type="text"
                      value={editInput}
                      onChange={(e) => setEditInput(e.target.value)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "4px",
                        border: "1px solid",
                        borderColor: isDarkMode ? "#3F3F46" : "#E5E7EB",
                        outline: "none",
                        backgroundColor: isDarkMode ? "#27272A" : "#FFFFFF",
                        color: isDarkMode ? "#FFFFFF" : "#000000",
                      }}
                    />
                    <button
                      onClick={handleSaveEdit}
                      style={{
                        marginTop: "8px",
                        padding: "4px 8px",
                        backgroundColor: "#10B981",
                        color: "#FFFFFF",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: "44px",
                      color: isDarkMode ? "#E4E4E7" : "#1F2937",
                      lineHeight: "1.6",
                    }}
                  >
                    {msg.type === "image" ? (
                      <img
                        src={msg.content}
                        alt="Generated content"
                        style={{
                          maxWidth: "100%",
                          borderRadius: "5px",
                          marginTop: "10px",
                        }}
                      />
                    ) : msg.type === "code" ? (
                      <SyntaxHighlighter
                        language="python"
                        style={isDarkMode ? dracula : prism}
                      >
                        {msg.content}
                      </SyntaxHighlighter>
                    ) : (
                      <ReactMarkdown>{msg.content}</ReactMarkdown>
                    )}
                  </div>
                )}

                {/* Message Actions */}
                <div
                  style={{
                    marginLeft: "44px",
                    marginTop: "12px",
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  {isUserMessage ? (
                    <>
                      <button
                        onClick={() => handleEditMessage(msg.id, msg.content)}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: isDarkMode ? "#A1A1AA" : "#6B7280",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          fontSize: "14px",
                        }}
                      >
                        <FaEdit size={14} />
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteMessage(msg.id)}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: isDarkMode ? "#A1A1AA" : "#6B7280",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          fontSize: "14px",
                        }}
                      >
                        <FaTrash size={14} />
                        Delete
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleCopyMessage(msg.content)}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: isDarkMode ? "#A1A1AA" : "#6B7280",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          fontSize: "14px",
                        }}
                      >
                        <FaCopy size={14} />
                        Copy
                      </button>
                      <button
                        onClick={() => handleFeedback(msg.id, "thumbsUp")}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color:
                            msg.feedback === "thumbsUp"
                              ? "#10B981"
                              : isDarkMode
                              ? "#A1A1AA"
                              : "#6B7280",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          fontSize: "14px",
                        }}
                      >
                        <FaThumbsUp size={14} />
                        Helpful
                      </button>
                      <button
                        onClick={() => handleFeedback(msg.id, "thumbsDown")}
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color:
                            msg.feedback === "thumbsDown"
                              ? "#EF4444"
                              : isDarkMode
                              ? "#A1A1AA"
                              : "#6B7280",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          fontSize: "14px",
                        }}
                      >
                        <FaThumbsDown size={14} />
                        Unhelpful
                      </button>
                    </>
                  )}
                </div>
              </div>
            );
          })}

          {/* Model Selection UI (existing dummy) */}
          {showModelSelection && (
            <div
              style={{
                padding: "16px",
                backgroundColor: isDarkMode ? "#27272A" : "#F3F4F6",
                borderRadius: "8px",
                marginTop: "16px",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "12px",
                  color: isDarkMode ? "#FFFFFF" : "#000000",
                }}
              >
                Select a model to use for this query:
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginBottom: "16px",
                }}
              >
                {availableModels.map((model) => (
                  <button
                    key={model.id}
                    onClick={() => proceedWithSelectedModel()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      padding: "10px",
                      backgroundColor: isDarkMode ? "#3F3F46" : "#FFFFFF",
                      border: "1px solid",
                      borderColor: isDarkMode ? "#52525B" : "#D1D5DB",
                      borderRadius: "6px",
                      cursor: "pointer",
                      color: isDarkMode ? "#FFFFFF" : "#000000",
                    }}
                  >
                    <FaBrain size={16} color={isDarkMode ? "#A1A1AA" : "#6B7280"} />
                    <div style={{ textAlign: "left" }}>
                      <div style={{ fontWeight: "medium" }}>{model.name}</div>
                    </div>
                  </button>
                ))}
              </div>
              <button
                onClick={handleStopGenerating}
                style={{
                  padding: "8px 12px",
                  backgroundColor: "#EF4444",
                  color: "#FFFFFF",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
              >
                Cancel
              </button>
            </div>
          )}

          {/* Typing Indicator */}
          {isBotTyping && !showModelSelection && (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
                padding: "16px 0",
              }}
            >
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  backgroundColor: "#10B981",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
              >
                C
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  color: isDarkMode ? "#FFFFFF" : "#000000",
                }}
              >
                GenAI
              </div>
              <div className="typing-animation" style={{ marginLeft: "8px" }}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          )}

          {/* Always scroll to bottom */}
          <div ref={messagesEndRef} />
        </div>

        {/* INPUT AREA */}
        <div
          style={{
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto",
            padding: "16px 24px 24px",
            borderTop: "1px solid",
            borderColor: isDarkMode ? "#27272A" : "#E5E7EB",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "flex-end",
            }}
          >
            <label
              htmlFor="file-input"
              style={{
                padding: "10px",
                backgroundColor: "transparent",
                color: isDarkMode ? "#A1A1AA" : "#6B7280",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaPaperclip size={18} />
            </label>
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-input"
            />
            <div style={{ flex: 1, position: "relative" }}>
              <textarea
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Message GenAI..."
                style={{
                  width: "100%",
                  padding: "14px",
                  borderRadius: "8px",
                  border: "1px solid",
                  borderColor: isDarkMode ? "#3F3F46" : "#E5E7EB",
                  outline: "none",
                  fontSize: "16px",
                  minHeight: "50px",
                  maxHeight: "200px",
                  resize: "vertical",
                  backgroundColor: isDarkMode ? "#27272A" : "#FFFFFF",
                  color: isDarkMode ? "#FFFFFF" : "#000000",
                }}
              />
              <button
                onClick={() => handleSend()}
                style={{
                  position: "absolute",
                  bottom: "8px",
                  right: "8px",
                  padding: "6px",
                  backgroundColor: input.trim() ? "#10B981" : "transparent",
                  color: input.trim()
                    ? "#FFFFFF"
                    : isDarkMode
                    ? "#A1A1AA"
                    : "#6B7280",
                  border: "none",
                  borderRadius: "50%",
                  cursor: input.trim() ? "pointer" : "default",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: input.trim() ? 1 : 0.5,
                }}
                disabled={!input.trim()}
              >
                <FaPaperPlane size={16} />
              </button>
            </div>
          </div>
        </div>

        {/* SIDEBAR */}
        <div
          ref={sidebarRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "245px",
            height: "100vh",
            backgroundColor: isDarkMode ? "#202022" : "#F9FAFB",
            borderRight: "1px solid",
            borderColor: isDarkMode ? "#27272A" : "#E5E7EB",
            padding: "16px",
            overflowY: "auto",
            transform: isSidebarOpen ? "translateX(0)" : "translateX(-100%)",
            transition: "transform 0.3s ease",
            zIndex: 10,
          }}
          className="hide-scrollbar"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <h3 style={{ color: isDarkMode ? "#FFFFFF" : "#000000", margin: 0 }}>
              Conversations
            </h3>
            <button
              onClick={() => setIsSidebarOpen(false)}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
                color: isDarkMode ? "#A1A1AA" : "#6B7280",
              }}
            >
              <FaTimes size={18} />
            </button>
          </div>
          {conversations.map((conv, index) => (
            <button
              key={conv.id || index}
              style={{
                display: "block",
                width: "100%",
                padding: "8px 12px",
                backgroundColor:
                  conv.id === null
                    ? isDarkMode
                      ? "#3F3F46"
                      : "#E5E7EB"
                    : "transparent",
                color: isDarkMode ? "#FFFFFF" : "#000000",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                textAlign: "left",
                marginBottom: "4px",
                fontSize: "14px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {conv.timestamp
                ? new Date(conv.timestamp?.toDate()).toLocaleString()
                : "Unknown Date"}
            </button>
          ))}
          {/* Logout Button at Bottom Left */}
          <button
            onClick={handleLogout}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
              padding: "8px 12px",
              backgroundColor: "transparent",
              color: isDarkMode ? "#A1A1AA" : "#6B7280",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              textAlign: "left",
              marginTop: "auto",
              position: "absolute",
              bottom: "16px",
              left: "16px",
              right: "16px",
            }}
          >
            <FaSignOutAlt size={16} />
            <span>Sign out</span>
          </button>
        </div>

        {/* Sidebar Toggle Button */}
        {!isSidebarOpen && (
          <button
            onClick={() => setIsSidebarOpen(true)}
            style={{
              position: "absolute",
              top: "16px",
              left: "16px",
              padding: "8px",
              backgroundColor: "transparent",
              color: isDarkMode ? "#FFFFFF" : "#000000",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              zIndex: 5,
            }}
          >
            <FaBars size={20} />
          </button>
        )}

        {/* Typing Animation CSS */}
        <style>
          {`
            .typing-animation span {
              opacity: 0;
              animation: blink 1s infinite;
            }
            .typing-animation span:nth-child(1) {
              animation-delay: 0s;
            }
            .typing-animation span:nth-child(2) {
              animation-delay: 0.2s;
            }
            .typing-animation span:nth-child(3) {
              animation-delay: 0.4s;
            }
            @keyframes blink {
              50% {
                opacity: 1;
              }
            }
          `}
        </style>
      </div>
    </>
  );
};

export default Chatbot;