import React from "react";
import Chatbot from "./Chatbot";
import { useUser } from "./UserContext";
import "./DashboardPage.css";

const Dashboard = () => {
  const { user } = useUser();

  return (
    <div className="dashboard-container">
      <Chatbot user={user} />
    </div>
  );
};

export default Dashboard;
